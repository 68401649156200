import axios from 'axios';
import { useStore } from 'vuex';
import { flashMessage } from '@/composeables/helpers';

export function useDashboardApi() {
  const getDashboards = async (searchPhrase = '') => {
    const response = await axios.get(`/dashboard.json?search=${searchPhrase}`);
    return response.data;
  };

  const updateDashboard = async (id, params) => {
    const response = await axios.post(`/dashboard/${id}`, params);
    return response.data;
  };

  const createDashboard = async params => {
    const response = await axios.post('/dashboard', params);
    return response.data;
  };

  const deleteDashboard = async id => {
    const response = await axios.delete(`/dashboard/${id}`);
    return response.data;
  };

  const getActiveDashboards = async () => {
    const response = await axios.get('/user_active_dashboards');
    return response.data;
  };

  const fetchActiveDashboard = async (id, params) => {
    const active_dashboard = (await axios.get(`/user_active_dashboards/${id}`, { params: params }))?.data;
    const dashboard_versions = (await axios.get('/entity_version', { params: { id: id, entity: 'Dashboard' } }))?.data;
    return { ...active_dashboard, dashboard_versions: dashboard_versions };
  };

  const setActiveDashboard = async params => {
    const response = await axios.post('/user_active_dashboards', params);
    return response.data;
  };

  const deleteActiveDashboad = async id => {
    const response = await axios.delete(`/user_active_dashboards/${id}`);
    return response.data;
  };

  const addNewWidget = async widget => {
    const response = await axios.post('/dashboard_widgets', widget);
    return response.data;
  };

  const updateWidgets = async widgets => {
    //looping in order to handle position updates on multiple widgets
    if (widgets) {
      widgets.forEach(async w => {
        await axios.patch(`/dashboard_widgets/${w.id}`, { ...w });
      });
    }
  };

  const deleteWidget = async id => {
    const $store = useStore();
    $store.dispatch('confirm', 'Please confirm you want to remove this widget from dashboard?').then(() => {
      axios.delete(`/dashboard_widgets/${id}`).then(() => {
        flashMessage({
          type: 'success',
          message: 'The selected component has been deleted successfully'
        });
      });
    });
  };

  const getFavoritesAndRecents = async () => {
    const response = await axios.get('/dashboard_meta');
    return response.data;
  };

  const addDashboardMeta = async meta => {
    const response = await axios.post('/dashboard_meta', meta);
    return response.data;
  };

  const deleteDashboardMeta = async meta => {
    const response = await axios.delete(`/dashboard_meta/0`, {
      params: meta
    });
    return response.data;
  };

  const getAllTags = async () => {
    const response = await axios.get('/get_all_tags');
    return response.data;
  };

  return {
    getDashboards,
    createDashboard,
    updateDashboard,
    deleteDashboard,
    getActiveDashboards,
    fetchActiveDashboard,
    setActiveDashboard,
    deleteActiveDashboad,
    addNewWidget,
    updateWidgets,
    deleteWidget,
    getFavoritesAndRecents,
    addDashboardMeta,
    deleteDashboardMeta,
    getAllTags
  };
}
